import { useMemo, useState } from "react";
import { Modal } from "../../../components/Modals/Modal";
import { TimeOfDay } from "../../categories/components/TimeOfDay";
import { Button } from "../../../components/Button";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { api } from "../../../services/api";
import { RecommendationFrequency } from "../../categories/components/RecommendationFrequency";
import { statusList } from "./ActivityForm";
import { DaysOfWeek } from "./DaysOfWeek";

const title = {
  status: "Change Status",
  recommendation_frequency: "Change Frequency",
  days_of_week: "Change Days of Week",
  time_of_day: "Change Time of Day",
};

export function BulkChangeConfirmation({
  onClose,
  handleConfirm,
  loading,
  count,
  type,
}: {
  onClose: () => void;
  handleConfirm: () => void;
  loading: boolean;
  count: number;
  type: string;
}) {
  return (
    <div>
      <div>
        Are you sure you want to update the {type.replaceAll("_", " ")} of{" "}
        {count} experience together?
      </div>
      <div className="flex w-full gap-3 mt-7">
        <Button
          className="!rounded-full w-full"
          onClick={onClose}
          disabled={loading}
          variant="outline"
        >
          No, go back
        </Button>
        <Button
          className="!rounded-full w-full"
          loading={loading}
          onClick={handleConfirm}
        >
          Yes, update
        </Button>
      </div>
    </div>
  );
}

function StatusChange() {
  return <div>StatusChange</div>;
}

export function BulkChange({
  type,
  onClose,
  ids,
  onSuccess,
}: {
  type: string;
  onClose: () => void;
  ids: string[];
  onSuccess: () => void;
}) {
  const [activityForm, setActivityForm] = useState<any>({});
  const [confirm, setConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = () => {
    setConfirm(true);
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      console.log(ids, activityForm, type);
      const res = await api.updateV2({
        ids,
        [type]: activityForm[type],
        ...(type === "days_of_week" && {
          limited_time: activityForm.limited_time,
        }),
      });
      setConfirm(false);
      console.log(res);
      setLoading(false);
      onSuccess();
    } catch (error) {
      setConfirm(false);
      setLoading(false);
      console.log(error);
    }
  };

  const handleClose = () => {
    setConfirm(false);
    setActivityForm({});
    onClose();
  };

  const disabled = useMemo(() => {
    if (type === "status")
      return [undefined, null].includes(activityForm.status);
    if (type === "recommendation_frequency")
      return !activityForm.recommendation_frequency;
    if (type === "days_of_week") return !activityForm.days_of_week;
    if (type === "time_of_day") return !activityForm.time_of_day;
    return false;
  }, [activityForm, type]);

  return (
    <Modal
      contentClassName={`${
        type === "status" && !confirm ? "!max-w-[325px]" : "!max-w-[450px]"
      } [&_.title]:!text-lg [&_.title]:text-[#33363F]`}
      open={true}
      onClose={handleClose}
      title={title[type]}
    >
      {confirm ? (
        <BulkChangeConfirmation
          onClose={() => setConfirm(false)}
          handleConfirm={handleConfirm}
          loading={loading}
          count={ids.length}
          type={type}
        />
      ) : (
        <>
          {type === "status" && (
            <div className="flex items-center justify-between gap-2">
              {statusList.map((status) => (
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="status"
                    className="w-[17px] h-[17px] accent-[#3444DA]"
                    value={status.value}
                    id={status.value.toString()}
                    onChange={() =>
                      setActivityForm((prev) => ({
                        ...prev,
                        status: status.value,
                      }))
                    }
                    checked={activityForm.status === status.value}
                  />
                  <label
                    htmlFor={status.value.toString()}
                    className="text-base text-[#1D1E1B]"
                  >
                    {status.label}
                  </label>
                </div>
              ))}
            </div>
          )}
          {type === "recommendation_frequency" && (
            <RecommendationFrequency
              onChange={(value) =>
                setActivityForm((prev) => ({
                  ...prev,
                  recommendation_frequency: value,
                }))
              }
              value={activityForm.recommendation_frequency}
              showTitle={false}
            />
          )}
          {type === "days_of_week" && (
            <DaysOfWeek
              onChange={(field, value) => {
                setActivityForm((prev) => ({
                  ...prev,
                  [field]: value,
                }));
              }}
              value={activityForm.days_of_week}
              limitedTime={activityForm.limited_time || {}}
              showTitle={false}
            />
          )}
          {type === "time_of_day" && (
            <TimeOfDay
              onChange={(field, val) =>
                setActivityForm((prev) => ({
                  ...prev,
                  [field]: val,
                }))
              }
              value={activityForm.time_of_day || []}
              showTitle={false}
            />
          )}
          <div className="w-full mt-7">
            <Button
              className="!w-full !rounded-full"
              onClick={handleSave}
              disabled={disabled}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}
